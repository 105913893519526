.raw-filter {
  .accordion {
    --mdb-accordion-border-color: none !important;

    .accordion-button {
      box-shadow: 0px 4px 8px 2px #00000014;
      border-bottom-left-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      padding: 16px;
    }
    .accordion-body {
      box-shadow: 0px 4px 8px 2px #00000014;
    }
    // .accordion-button:not(.collapsed) {
    //     box-shadow: 0px 4px 0px 2px #00000014;
    // }
  }

  .ng-select .ng-select-container .ng-value-container {
    padding-left: 0;
  }

  .ng-select.ng-select-multiple .ng-select-container .ng-value-container {
    .ng-placeholder {
      font-size: $large-font;
      padding-top: 3px;
    }

    padding-left: 1rem;
  }

  .ng-select.ng-select-single
    .ng-select-container
    .ng-value-container
    .ng-input {
    position: absolute;
    left: 0;
    width: 100%;
    padding: 0;
  }
}
