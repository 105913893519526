.general {
    .nav {
        flex-wrap: nowrap;

    }

    tbody {
        tr:not(:last-child) {
            border-bottom: 0 !important;
        }

        td {
            padding: 8px !important;
            border-bottom: 0 !important;
        }
    }

    .nav-tabs {
        padding-top: 20px;
        height: auto !important;
        background: none;
        border-radius: 0 !important;
        margin: 0;
        border-bottom: 2px solid $gray-light;
        // width: 100vh !important;
        // overflow-x: auto;
        overflow-x: scroll;
        white-space: nowrap;

        a {
            padding: 8px 16px 8px 16px !important;
            text-align: center !important;
        }
    }

    .nav-tabs::-webkit-scrollbar {
        display: none !important;
        width: 0;
        padding: 0;
        /* Hides the vertical scrollbar */
    }

    .nav-link.active {
        background: none !important;
        border-bottom: 2px solid $primary !important;
        border-radius: 0 !important;
        margin: 0 !important;
        color: $primary !important;
    }
    hr {
        height: 2px;
        color: #F8F9FA;
        opacity: 100;
    }

    .ng-clear-wrapper {
        display: none;
    }

    .datepicker-toggle-button {
        // right: 480px !important;
        // top: auto !important;
        display: none;
    }

    .logo-bg {
        height: 80px;
        width: 80px;
        background-color: $gray-lighter;
        border-radius: 4px;
        // overflow: hidden;
        
    }
    .client-logo {
        max-width: 65px;
        max-height: 100%;
        display: block;
        margin: auto;
    }
    .default-logo {
        color: $gray-main;
        display: inline-block;
        vertical-align: -0.125em;
    }
}

.collapse-container {
    position: relative;
    .divider {
        width: 100%;
        position: absolute;
        .divider-hr {
            position: relative;
            margin-top : 40px;
            width: 90%;
            margin-left: 100px;
        }
        .divider-label {
            position: absolute;
            z-index: 3;
            bottom: 25px;
            left: 15px
        }
    }
}
.table-div {
    margin-top: 80px;
}

.upload {
    .file-upload {
        border: 2px dashed var(--colors-gray-light, #B3B3B3);
    }
    table {
        td {
           padding: 12px 4px 4px 4px !important; 
        }
    }
}
