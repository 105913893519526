.btn {
  text-transform: unset;
  height: $btn-height;
  box-shadow: none;
  padding: 10px 20px 10px 20px;
  border-radius: 4px;

  &:hover:not(.btn-light, .btn-clear, .row-btn, .table-row-btn), &:active {
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    color: $white !important;
  }

  &-floating {
    &.dropdown-toggle {
      &:after {
        display: none;
      }
    }
  }

  &-lg {
    height: $btn-height-lg;
  }

  &-gradient {
    background: $primary-gradient;
    color: white;
  }

  &-clear {
    color: $dark;
    background-color: transparent;
    &:hover,
    &:focus { 
      color: $dark !important;
     }
  }

  &-secondary,
  &-secondary:disabled {
    background-color: $gray-main;
    color: white;
    &:hover,
    &:focus {
      background-color: $gray-main !important;
      color: white;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.1);
    }
  }

  &-light, &-gray-light:hover {
    color: $dark !important;
    background-color: $gray-light !important; 
  }
  
  &-primary:disabled {
    color: white;
  }
}

.navbar-toggler {
  position: absolute;
  z-index: $zindex-popover;
  top: 8px;
  background: $primary-gradient;
}

.slim-height {
  min-height: 3.75rem;
}

.btn-floating.btn-sm,
.btn-group-sm>.btn-floating.btn {
  width: 44px !important;
  height: 44px !important;

  i {
    font-size: $large-font;
  }
}

.row-btn {
  // padding: 8px 16px 8px 16px;
  border-radius: 0;
  height: 50px;
  &:hover, &:focus {
    box-shadow: none !important;
    color: black !important;
  }
}

.table-row-btn {
  border-radius: 4px;
  height: 50px;
  &:hover, &:focus {
    box-shadow: none !important;
    color: black !important;
  }
  border: 1px solid $selected-bg !important
}