.sidenav {
    background-color: $gray-darker;
    position: fixed;
    left: 0;
    top: 0;
    font-size: $large-font !important;
    z-index: 1;
}
.app-sidenav {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.sidenav-menu {
    flex: 1;
    padding: 0.5rem;
}

.sidenav-link {
    padding: 0;
    display: flex;
    align-items: center;
    font-size: $large-font;
    color: $white;
    border-left: 3px solid transparent;

    &:hover {
        .sidenav-primary & {
            background: $gray-dark  !important;
            color: $white  !important;
        }
    } 

    &.active, &:focus{
        color: $primary !important;
        @extend .font-weight-bold !optional;
        background: $gray-dark !important;
    }
}

.sidenav-collapse .sidenav-link {
    font-size: $large-font !important;
}

[ng-reflect-slim ="true"]{
    .rotate-icon {
        display: none;
    }

}
[ng-reflect-slim ="false"]{
    .rotate-icon {
        display: inline-block;
    }

}
.slim-space {
    padding-top:70px;
    padding-left:15px
}

