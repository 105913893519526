.ut-block {
    border-radius: 4px;
    // border: 1px solid $gray-light;
    height: 429px !important;
}

// .calc-block {
//     border-radius: 4px;
//     border: 1px solid $gray-light;
//     height: 211px !important;
// }

.note-block {
    border-radius: 4px;
    border: 1px solid $gray-light;
}

.sv-block {
    border-radius: 4px;
    // border: 1px solid $gray-light;
    height: 416px !important;
}

.tat-block {
    height: 274px;
    border-radius: 4px;
}

//care option report
.care-report {
    width: 1073px;
    box-shadow: 0px 4px 16px 4px #0000000F;
}

.vertical-line {
    border-left: 2px solid $selected-bg;
    height: 70px;
}

.tat-label {
    left: 40px;
    top: 45px;
}

.table-report {
    table {
        background-color: none !important;
    }

    th {
        padding: 8px !important;
    }

    td {
        padding: 16px 8px 16px 8px !important;
    }

}

.table-incentive {
    tbody {

        .bordered td:not(:last-child) {
            border-right: 0.5px solid $border-dark
        }
    }

    thead {
        tr {
            border-bottom: 0.5px solid $border-dark !important;
            background-color: $selected-bg !important;
        }

        .bordered td:not(:last-child),
        th:not(:last-child) {
            border-right: 0.5px solid $border-dark !important;
        }
    }
}

.table-detailed {

    .bordered td:not(:last-child),
    th:not(:last-child) {
        border-right: 0.5px solid $border-dark
    }

    thead {
        tr {
            border-bottom: 0.5px solid $border-dark !important;
            background-color: $selected-bg !important;
        }
    }
}

.table-ddr {
    tbody {

        .bordered td:nth-child(1):not(:last-child),
        .bordered td:nth-child(4):not(:last-child),
        .bordered td:nth-child(7):not(:last-child),
        .bordered td:nth-child(10):not(:last-child),
        .bordered td:nth-child(13):not(:last-child),
        .bordered td:nth-child(14):not(:last-child),
        .bordered td:nth-child(15):not(:last-child),
        .bordered td:nth-child(16):not(:last-child),
        .bordered td:nth-child(18):not(:last-child),
        .bordered td:nth-child(19):not(:last-child),
        .bordered td:nth-child(20):not(:last-child),
        .bordered td:nth-child(22):not(:last-child),
        .bordered td:nth-child(23):not(:last-child),
        .bordered td:nth-child(24):not(:last-child) // .bordered td:nth-child(25) 

            {
            border-right: 0.5px solid $border-dark
        }
    }

    thead {
        tr {
            border-bottom: 0.5px solid $border-dark !important;
            background-color: $selected-bg !important;
        }

        .bordered td:nth-child(1):not(:last-child),
        .bordered td:nth-child(4):not(:last-child),
        .bordered td:nth-child(7):not(:last-child),
        .bordered td:nth-child(10):not(:last-child),
        .bordered td:nth-child(13):not(:last-child),
        .bordered td:nth-child(14):not(:last-child),
        .bordered td:nth-child(15):not(:last-child),
        .bordered td:nth-child(16):not(:last-child),
        .bordered td:nth-child(18):not(:last-child),
        .bordered td:nth-child(19):not(:last-child),
        .bordered td:nth-child(20):not(:last-child),
        .bordered td:nth-child(22):not(:last-child),
        .bordered td:nth-child(23):not(:last-child),
        .bordered td:nth-child(24):not(:last-child),
        // .bordered td 
        .bordered th:not(:last-child) {
            border-right: 0.5px solid $border-dark !important;
        }
    }
}

.table-lucky {
    thead {
        tr {
            border-bottom: 0.5px solid $border-dark !important;
            background-color: $selected-bg !important;
        }

        .bordered th:not(:last-child),
        .bordered td:not(:last-child) {
            border-right: 0.5px solid $border-dark !important;
        }
    }

    tbody {

        .bordered td:not(:last-child) {
            border-right: 0.5px solid $border-dark !important;
        }
    }
}

.footer {
    height: 50px;
    bottom: 0;
    width: 100%;
}


//tinymc text editor
.tox-tinymce {
    border-radius: 0 !important;
    background-color: $gray-light !important;
    font-family: 'Montserrat', sans-serif !important;
}

.tox-statusbar {
    display: none !important;
}

.tox .tox-edit-area__iframe,
.tox:not(.tox-tinymce-inline) .tox-editor-header,
.tox .tox-toolbar-overlord,
.tox .tox-toolbar__primary,
.tox .tox-tbtn--bespoke {
    background-color: transparent !important;
    font-family: 'Montserrat', sans-serif !important;
}

//crm
.crm-list {
    top: -60px !important;
    left: -150px !important;
}

.no-scroll {
    .ng-dropdown-panel ::-webkit-scrollbar {
        display: none !important;
        width: 0;
        padding: 0;
        /* Hides the vertical scrollbar */
    }

    .ng-option {
        label {
            padding-left: 0 !important;
        }
    }
}