.tabSet {

    padding: 24px 16px 8px 16px;

    a {
        text-transform: none;
        color: $gray-darker;
        font-weight: bold;
    }

    &.active {
        border-bottom: 3px solid $primary;
        // padding-bottom: 7px;
    }
}

.multi-select-company {
    .ng-select .ng-select-container {
        top: -3px
    }

}
.member-profile {
    .datepicker-toggle-button {
        top: 60px !important;
        right: 20px !important;
    }
}