//login

.login-background {
    background-image: url('../../images/login-background-image.jpg');
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-size: 100% 100%;
}

.clickable {
    color: $primary;
}

.pointer {
    cursor: pointer !important;
}
.not-allowed {cursor: not-allowed;}

//welcome
.welcome-page {
    i {
        font-size: 70px;
        background-image: $primary-gradient;
        color: transparent;
        -webkit-background-clip: text;
        background-clip: text;
    }
}

//modal
.modal-content {
    border-radius: 4px;

    .modal-header {
        box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.08);
    }
}
.modal {
    &-md {
        width: 700px !important;
    }

    &-lg {
        width: 800px !important;
    }

    &-sm {
        width: 600px !important;
    }
}

//search-bar 
.advanced-collapse {
    border-radius: 4px;
    border: 1px solid $gray-light;
    box-shadow: 0px 4px 8px 2px rgba(0, 0, 0, 0.08);
}
.container-height {
    height: calc(100% - 3.75rem);
}

.light-bg {
    background-color: $gray-lighter;
}

//popovers
.popover {
    background: $gray-main ;
    .popover-body {
        color: white;
        font-size: $small-font;
        padding: 8px 16px;
    }
}