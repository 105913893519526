table {
    font-size: $large-font !important;
}
td:not(.datepicker-cell), th:not(.datepicker-cell){
    vertical-align: middle;
    padding: 16px !important;
}

.table-member {
    background-color: white;

    tr:last-child {
        border-bottom: transparent;
    }
}

.row-striped {
    background-color: $table-striped-bg !important;
}

table > tbody >tr{
    border-bottom: 1px solid $border-light;
}

.table-row {
    td:not(.datepicker-cell), th:not(.datepicker-cell){
        padding: 8px 16px 8px 16px !important
    }
}

.table-min-row {
    td:not(.datepicker-cell), th:not(.datepicker-cell){
        padding: 8px 8px 8px 8px !important;
        // white-space: nowrap  !important;
    } 
}

.ep-table::-webkit-scrollbar {
    display: none !important;
    width: 0;
    padding: 0;
    /* Hides the vertical scrollbar */
}