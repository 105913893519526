.db-sec {
    border: 1px solid #F2F2F2;
    table {
        font-size: 14px !important;
        td,
        th {
            padding: 8px !important;
        }
        tr:not(thead > tr):last-child {
            border-bottom: transparent;
        }
    }

    i-bs svg {
        height: 20px;
        width: 20px;
    }
}

.dashboard {
    .card {
        box-shadow: 0 4px 13px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.1);
        border-radius: 4px;
    }
}

.stats {
    height: 375px;
    overflow: hidden;

    canvas {
        height: 300px !important;
    }
}

.a-hover {
    color: black;

    :hover {
        color: $primary !important;
    }
}