.smart-search {
    width: 1150px;
    .listing {
        border: 1px solid $selected-bg;

        a {
            color: black;
        }

        .active {
            color: $primary;
            border-bottom: 2px solid $primary;

            a {
                color: $primary;
            }
        }

        table {
            td {
                vertical-align: text-top !important;
            }

            tbody>tr:last-child {
                border-bottom: transparent;
            }
        }

        .list-group-item {
            background-color: transparent !important;
        }
    }
}