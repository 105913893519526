label {
  font-size: 0.875rem;
}

.form-control {
  border: none;
  font-size: $input-font-size;
  padding: $input-padding-x $input-padding-y;
}

.warning-input {
  border: 1.5px solid $error-light;
}

.form-control:disabled,
.form-control[readonly] {
  // opacity: 55%;
  background-color: $selected-bg;
  color: $gray-dark;
}

.form-control:focus {
  border: 1px solid $gray-main !important;
  box-shadow: none;
}

.autocomplete-input.focused {
  box-shadow: none !important;
  border: 0 !important;
}

.form-control:not(textarea):not(.ng-select-multiple) {
  height: $input-height !important;
}

.input-group {
  &-lg {
    &:focus {
      transition: $input-group-focus-transition;
      border: 1px solid $input-group-focus-border-color;
      outline: 0;
    }

    &>.form-control {
      // padding-left: 0 !important;

      &:focus {
        border-color: transparent;
        box-shadow: none;
      }
    }
  }

  &-text {
    height: $input-height !important;
    border: none;
    background: $input-bg;
  }
}

.limit-input {
  background: rgba(153, 153, 153, 0.13);
  border-radius: 4px;
  width: 145px;

  // height: 44px;
  .ng-clear-wrapper {
    display: none;
  }
}

.form-outline {
  .form-control {
    background: #eceff1;
    border: #eceff1;
  }
}

.app-brand {
  height: $navbar-height;
  padding: 0.625rem 1.5rem;
  position: relative;
}

main {
  padding-left: 250px;
}

.form-check-input {
  border: 1px solid $gray-main;
}

.ng-select .ng-select-container {
  background-color: transparent;
  border: none;

  .ng-placeholder {
    padding-top: 0.25rem !important;
    color: #4f4f4f !important;
  }
}

.ng-select.ng-select-opened.ng-select-bottom>.ng-select-container {
  background-color: transparent
}

.ng-select .ng-select-container:hover {
  box-shadow: none;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border: none;
  box-shadow: none;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value {
  background-color: rgba(153, 153, 153, 0.2);
  border-radius: 4px;
  color: $dark;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-value .ng-value-icon.left {
  border-right: 1px solid $gray-lighter;
}

.ng-invalid:not(form):not(div):not(select):not(mdb-date-picker):not(tbody):not(table):not(tr) {
  border: 1px solid $error-light !important;
}

textarea.form-control {
  min-height: auto;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-selected {
  background-color: #99999921;

  .ng-option.ng-option-marked {
    background-color: $gray-lighter;
  }
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option.ng-option-marked {
  background-color: $gray-lighter;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.assign-company-dropdown {
  .ng-select {
    .ng-select-container {
      max-height: 150px !important;
      overflow-y: scroll !important;

      .ng-clear-wrapper,
      .ng-arrow-wrapper {
        display: none;
      }
    }
  }
}