.nav-tabs:not(.member-profile) {
    padding: 0;
    background: #F8F9FA;

    // width: auto !important;
    // height: 100vh !important;
    // overflow: auto;
    .nav-link {
        color: $dark;
        font-weight: 400;
        text-align: left;
        text-transform: capitalize;
        font-size: 14px;
    }

    .nav-link.active,
    .nav-tabs .nav-item.show .nav-link {
        border: none;
        background: $selected-bg;
        color: $gray-darker;
        border-radius: 4px;
        font-weight: 500;
        // margin: 0 10px 0 8px;
    }
}

.company-vetical-tab{
    width: 270px !important;
    padding: 0 8px 0 8px !important;
}

.vertical-tab{
    .col-9 {
        width: calc(100% - 270px) !important;
        padding: 0 16px 0 16px !important;
    }
}


.nav-tabs .nav-link.disabled{
    color: $gray-main !important;    
}

