header {
    &.sticky-top {
        z-index: 1000;
    }
}

.navbar {
    height: $navbar-height;
    .nav-item {
        padding-left: $nav-link-padding-x;
        padding-right: $nav-link-padding-x;
        a {
            color:$gray-main;
        }
        i {
            color: $gray-main;
            font-size: 1.5rem;
        }
    }

    &-submenu {
        padding: 0;

        .submenu-item {
            display: inline-block;
            height: $navbar-height;
            vertical-align: middle;
            line-height: $navbar-height;
            padding: 0 $nav-link-padding-x;
            font-size: 0.875rem;

            &:first-child {
                font-size: 1rem;
            }

            a {
                color: $body-color;
            }

            &.active {
                font-weight: $font-weight-bold;
                border-bottom: 3px solid $primary;
            }
        }
    }
}

.avatar {
    background-color: $secondary;
    height: 44px;
    width: 44px;
    border-radius: 50%;
    color: white;
    font-size: $large-font;
    position:relative;
    .av-initial{
        position: absolute;
        top:24%;
        left:28%;
    }
}