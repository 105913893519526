.permission-badge {
    background-color: rgba(153, 153, 153, 0.13);
    border-radius: 4px;
    color: $dark;
}

.accordion-button {
    padding-right: 8px;
    padding-left: 0;
    padding-top: 15px;
}

.accordion-button::after {
    background-size: 16px !important;
}

.user-list {
    top: -170px !important;
    left: -150px !important;
}