/* You can add global styles to this file, and also import other style files */
@import '@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '@fortawesome/fontawesome-free/scss/solid.scss';
@import '@fortawesome/fontawesome-free/scss/regular.scss';
@import '@fortawesome/fontawesome-free/scss/brands.scss';

@import "~@ng-select/ng-select/themes/default.theme.css";

@import 'assets/scss/_custom-variable.scss'; 
@import 'mdb-angular-ui-kit/assets/scss/mdb.scss';
@import 'assets/scss/_custom-style.scss';
@import 'mdb-angular-file-upload/scss/file-upload.scss';
@import 'mdb-angular-drag-and-drop/scss/drag-and-drop.scss';
